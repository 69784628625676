@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-Rubik text-white scroll-smooth;
  }

  html {
    @apply bg-black text-white scroll-smooth overflow-x-hidden;
  }

  body {
    @apply overflow-x-hidden;
  }
}

@layer components {
  .container {
    /* 1440px = 90rem */
    /* 1600px = 100rem */

    @apply w-[90%] lg:w-[80%] max-w-[90rem] mx-auto;
  }

  .gradient-button {
    @apply bg-green-to-blue rounded-full text-white font-semibold uppercase;
  }

  .mb-100px {
    @apply mb-[100px];
  }
  .mb-150px {
    @apply xl:mb-[150px];
  }
  .mb-200px {
    @apply mb-[200px];
  }

  .pb-100px {
    @apply pb-[100px];
  }
  .pb-150px {
    @apply pb-[150px];
  }
  .pb-200px {
    @apply pb-[200px];
  }
}

.box-styling {
  background: linear-gradient(180deg, #013c43 -1.32%, #1f0021 100%);
  box-shadow: inset 14px -6px 20px rgba(255, 255, 255, 0.3),
    inset -10px 20px 40px rgba(130, 34, 152, 0.4);
}

.join-private-sales {
  background: #1c281f;
  box-shadow: 0px 8px 25px rgba(31, 229, 68, 0.43);
}

.roadmap-line {
  box-shadow: 0px 0px 20px rgba(31, 230, 68, 0.6);
}

.roadmap-card {
  background: linear-gradient(
    180deg,
    rgba(252, 92, 220, 0.1) 0%,
    rgba(135, 135, 255, 0.1) 100%
  );
  box-shadow: inset 14px -6px 20px rgba(255, 255, 255, 0.3),
    inset -10px 20px 40px #4c2c9f;
}

.roadmap-dot {
  background: #1fe644;
  box-shadow: 0px 0px 20px rgba(31, 230, 68, 0.6), inset 0px 0px 6px #034e11,
    inset 0px 0px 4px #026414;
}

.black-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 110 !important;
  transition: all 0.4s ease;
  opacity: 0;
  pointer-events: none;
}
.black-screen.show {
  opacity: 1;
  pointer-events: visible;
}
